import React from "react";
import { Stack, Typography, IconButton, Divider, Button } from "@mui/material";

import {
  CiBookmark,
  CiCreditCard1,
  CiGrid31,
  CiMemoPad,
  CiMenuBurger,
} from "react-icons/ci";
import Popper from "@mui/material/Popper";
import { HiCreditCard, HiXMark } from "react-icons/hi2";
import { CiLogout, CiSearch, CiUser } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

export default function MiniMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <Popper style={{ zIndex: 10 }} id={id} open={open} anchorEl={anchorEl}>
        <Stack
          sx={{
            background: "white",
            boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.1)",
            color: "black",
            borderRadius: 3,
            width: 200,
            p: 4,
            mr: {
              xsm: 4,
              xs: 4,
              sm: 7,
              md: 7,
              lg: 20,
              xl: 20,
            },
            mt: 2,
            zIndex: 10,
          }}
        >
          <Stack
            justifyContent="space-between"
            direction="row"
            sx={{ alignItems: "center" }}
          >
            <Typography sx={{}}>
              <b>Menu</b>
            </Typography>
            <IconButton
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <HiXMark />
            </IconButton>
          </Stack>
          <Stack sx={{ mt: 2 }} spacing={1}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setAnchorEl(null);
                setTimeout(() => {
                  document
                    .getElementById("pricing")
                    .scrollIntoView({ behavior: "smooth" });
                }, 100);
              }}
              startIcon={<CiCreditCard1 />}
              sx={{
                textTransform: "none",
                borderRadius: 32,
                color: "black",
                background: "rgb(0, 93, 235,  0.0)",
                "&: hover": {
                  color: "white",
                },
              }}
            >
              Pricing
            </Button>
            <Button
              onClick={() => {
                setAnchorEl(null);
                setTimeout(() => {
                  document
                    .getElementById("features")
                    .scrollIntoView({ behavior: "smooth" });
                }, 100);
              }}
              variant="contained"
              disableElevation
              sx={{
                textTransform: "none",
                borderRadius: 32,
                color: "black",
                background: "rgb(0, 93, 235,  0.0)",
                "&: hover": {
                  color: "white",
                },
              }}
              startIcon={<CiGrid31 />}
            >
              Features
            </Button>
            <Button
              onClick={() => {
                setAnchorEl(null);
                setTimeout(() => {
                  document
                    .getElementById("subjects")
                    .scrollIntoView({ behavior: "smooth" });
                }, 100);
              }}
              startIcon={<CiMemoPad />}
              variant="contained"
              disableElevation
              sx={{
                textTransform: "none",
                borderRadius: 32,
                color: "black",
                background: "rgb(0, 93, 235,  0.0)",
                "&: hover": {
                  color: "white",
                },
              }}
            >
              Subjects
            </Button>
            <Button
              onClick={() => {
                setAnchorEl(null);
                window.location.href = "https://blog.qflit.com";
              }}
              startIcon={<CiBookmark />}
              variant="contained"
              disableElevation
              sx={{
                textTransform: "none",
                borderRadius: 32,
                color: "black",
                background: "rgb(0, 93, 235,  0.0)",
                "&: hover": {
                  color: "white",
                },
              }}
            >
              Blog
            </Button>
          </Stack>
        </Stack>
      </Popper>

      <IconButton sx={{}} onClick={handleClick}>
        <CiMenuBurger />
      </IconButton>
    </>
  );
}
