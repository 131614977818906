import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import TermsOfService from "./pages/terms";
import PrivacyPolicy from "./pages/privacy";
import { Homepage } from "./pages/home";

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: [
      "Public Sans",
      "sans-serif", // Fallback font
    ].join(","),

    // Setting default font weights
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          transition: "all ease 1s",
        },
      },
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
