import React from "react";
import logo from "../assets/logo.png";
import blurOverlay from "../assets/overlay_3.jpg";
import lightOne from "../assets/long-form.webp";
import girl from "../assets/girl.webp";
import longFormHor from "../assets/long-form-horizontal.webp";
import { IoDocuments } from "react-icons/io5";
import bgArt from "../assets/saas-bg.jpg";
import MiniMenu from "./miniMenu";
import feedbackImg from "../assets/feedback.webp";
import {
  CiGrid2V,
  CiLocationArrow1,
  CiMemoPad,
  CiMicrophoneOn,
  CiPen,
  CiPhone,
  CiRoute,
} from "react-icons/ci";
import standardPlanSvg from "../assets/art/standardPlan.svg";
import basicPlanSvg from "../assets/art/basicPlan.svg";
import premiumPlanSvg from "../assets/art/premiumPlan.svg";
import girlReal from "../assets/girl.jpg";
import main_screen from "../assets/main_screen.webp";
import mobile_screen from "../assets/mobile-screen.webp";

import {
  Stack,
  Typography,
  Button,
  Rating,
  Avatar,
  Chip,
  Grid,
  Collapse,
  IconButton,
  Divider,
  ButtonBase,
  Drawer,
} from "@mui/material";
import {
  HiArrowLongRight,
  HiArrowRight,
  HiBolt,
  HiChatBubbleOvalLeft,
  HiCheck,
  HiCheckCircle,
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiClock,
  HiCreditCard,
  HiMiniBriefcase,
  HiXCircle,
  HiXMark,
} from "react-icons/hi2";

const menuOptions = [
  { title: "Home", active: true, id: null, icon: <CiGrid2V /> },
  { title: "Features", id: "features", icon: <CiMemoPad /> },
  { title: "Pricing", id: "pricing", icon: <HiCreditCard /> },
  { title: "Subjects", id: "subjects" },
  {
    title: "Blog",
    action: () => {
      window.location.href = "https://blog.qflit.com";
    },
  },
];

const packages = [
  {
    title: "Free Plan",
    description:
      "Explore the basics of IGCSE/GCSE revision with our Free Package, offering up to 2 practice sessions per day, perfect for casual learners.",
    benefits: [
      "Up to 2 practice sessions every day",
      "Access to all available subjects",
    ],
    price: "£0.00",
    packageId: "price_1OafGLGbFCkF3tk4nMmKpLvd",
  },
  {
    title: "Premium Plan",
    description:
      "Unlock unlimited learning potential with our Premium Package, featuring unrestricted access to practice sessions anytime, ideal for dedicated students aiming for excellence.",
    benefits: [
      "Unlimited Practice Sessions",
      "Access to all available subjects",
    ],
    price: "£15.99",
    packageId: "price_1OafFeGbFCkF3tk4D6zcSjOV",
  },
];

function AnimatedComponentWrapper({ children }) {
  return <div>{children}</div>;
}

function NavBar({ setMenuOpen }) {
  return (
    <Stack
      sx={{ p: 4, pt: 3, pb: 2, zIndex: 2 }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          background: "black",
          p: 2,
          borderRadius: 32,
          color: "#1ACEA2",
          alignSelf: "flex-start",
          alignItems: "center",
          pt: 1,
          pb: 1,
        }}
      >
        <Stack
          sx={{
            height: {
              sm: "30px",
              xsm: "30px",
              xs: "30px",
              md: "35px",
              lg: "30px",
              xl: "30px",
            },
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "100%", objectFit: "contain" }}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center">
        <Stack
          sx={{
            display: {
              xs: "none",
              xsm: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            mr: 3,
          }}
          spacing={4}
          direction="row"
          alignItems="center"
        >
          {menuOptions.map(({ title, active, id, action }) => (
            <ButtonBase
              onClick={() => {
                if (id) {
                  document
                    .getElementById(id)
                    .scrollIntoView({ behavior: "smooth" });
                } else {
                  action();
                }
              }}
              sx={{ borderRadius: 32, p: 1 }}
            >
              <Typography
                sx={{
                  color: active ? "#00A632" : "black",
                  fontWeight: active ? "700" : "600",
                  fontSize: "1rem",
                }}
              >
                {title}
              </Typography>
            </ButtonBase>
          ))}
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => {
              window.open("https://app.qflit.com", "_blank");
            }}
            sx={{
              borderRadius: 32,
              background: "#192029",
              pl: 3,
              pr: 3,
              textTransform: "none",
              color: "white",
              "&: hover": {
                background: "black",
                color: "white",
              },
            }}
          >
            Open App
          </Button>

          <MiniMenu />
        </Stack>
      </Stack>
    </Stack>
  );
}

const AnimatedImage = ({ lightOne }) => {
  const outerWrapperStyle = {
    height: "100%",
    width: "100%",
    overflow: "hidden", // Hides the skewed edges of the inner image container
  };
  const imageContainerStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    paddingLeft: "60px",
    paddingRight: "60px",
  };

  const imageStyle = {
    height: "120%", // Slightly larger to account for skewing
    width: "100%",
    backgroundImage: `url(${lightOne})`,
    backgroundSize: "cover",
    animation: "scrollImage 20s linear infinite",
    transform: "skewX(-10deg)",
    transformOrigin: "right",
  };

  return (
    <div style={outerWrapperStyle}>
      <div style={imageContainerStyle}>
        <div style={imageStyle}></div>
      </div>
    </div>
  );
};

const AnimatedImageHor = () => {
  const outerWrapperStyle = {
    width: "100%",
    overflow: "hidden", // Hides the skewed edges of the inner image container
  };
  const imageContainerStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
  };

  const imageStyle = {
    width: "100%",
    height: 200,
    backgroundImage: `url(${longFormHor})`,
    backgroundSize: "cover",
    animation: "scrollImageHor 25s linear infinite",
  };

  return (
    <div style={outerWrapperStyle}>
      <div style={imageContainerStyle}>
        <div style={imageStyle}></div>
      </div>
    </div>
  );
};

function HeroBanner() {
  return (
    <Stack
      direction="row"
      sx={{
        flex: 1,
        height: {
          xs: "480px",
          xsm: "480px",
          sm: "646px",
          md: "646px",
          lg: "646px",
          xl: "646px",
        },
        pl: 3,
        pr: 3,
      }}
    >
      <Stack
        sx={{
          flex: 1,
          textAlign: "center",
          pr: {
            xsm: 0,
            sm: 0,
            xs: 0,
            md: 5,
            lg: 5,
            xl: 5,
          },
          pt: 6,
        }}
      >
        <Typography
          sx={{
            display: {
              xsm: "none",
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            alignSelf: "center",
          }}
          variant="h2"
        >
          <b>
            More wins,
            <br />
            less effort with
          </b>
        </Typography>
        <Typography
          sx={{
            display: {
              xsm: "flex",
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
            alignSelf: "center",
          }}
          variant="h4"
        >
          <b>
            More wins,
            <br />
            less effort with
          </b>
        </Typography>
        <Typography
          variant="h4"
          sx={{
            background:
              "linear-gradient(300deg, rgb(0, 167, 111) 0%, rgb(255, 171, 0) 25%, rgb(0, 167, 111) 50%, rgb(255, 171, 0) 75%, rgb(0, 167, 111) 100%)",
            WebkitBackgroundClip: "text", // For Webkit browsers
            WebkitTextFillColor: "transparent", // For Webkit browsers
            backgroundClip: "text", // For other browsers
            color: "transparent", // For other browsers

            mt: 1,
            display: {
              xsm: "inline",
              xs: "inline",
              sm: "inline",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <b>Qflit AI</b>
        </Typography>
        <Typography
          variant="h2"
          sx={{
            background:
              "linear-gradient(300deg, rgb(0, 167, 111) 0%, rgb(255, 171, 0) 25%, rgb(0, 167, 111) 50%, rgb(255, 171, 0) 75%, rgb(0, 167, 111) 100%)",
            WebkitBackgroundClip: "text", // For Webkit browsers
            WebkitTextFillColor: "transparent", // For Webkit browsers
            backgroundClip: "text", // For other browsers
            color: "transparent", // For other browsers

            mt: 1,
            display: {
              xsm: "none",
              xs: "none",
              sm: "none",
              md: "inline",
              lg: "inline",
              xl: "inline",
            },
          }}
        >
          <b>Qflit AI</b>
        </Typography>
        <Typography
          sx={{
            maxWidth: 450,
            textAlign: "center",
            mt: 3,
            alignSelf: "center",
            fontSize: {
              xs: 18,
              xsm: 18,
              sm: 20,
              lg: 23,
              xl: 23,
            },
          }}
        >
          Ace your GCSE/IGCSE exams with ease. Unlock personalized feedback,
          pinpoint your weaknesses, and enjoy on-the-go lessons.
        </Typography>

        <Stack
          spacing={2}
          direction="row"
          sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}
        >
          <Rating precision={0.5} name="read-only" value={4.5} readOnly />
          <Typography variant="caption">
            <b>4.5/5</b>
          </Typography>
          <Typography variant="caption" sx={{ opacity: 0.7 }}>
            {"(54 reviews)"}
          </Typography>
        </Stack>
        <Button
          onClick={() => {
            window.open("https://app.qflit.com/signup", "_blank");
          }}
          endIcon={<HiArrowLongRight />}
          sx={{
            borderRadius: 32,
            background: "#192029",
            pl: 3,
            pr: 3,
            textTransform: "none",
            color: "white",
            alignSelf: "center",
            height: 60,
            mt: 3,
            "&: hover": {
              color: "black",
            },
          }}
        >
          Sign up for free
        </Button>
      </Stack>
      <Stack
        sx={{
          flex: 1,
          display: {
            xs: "none",
            xsm: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <AnimatedImage lightOne={lightOne} />
      </Stack>
    </Stack>
  );
}

function SubjectsShowcase() {
  const [activeIndex, setIndex] = React.useState(0);

  const comparisons = [
    {
      aspect: (
        <>
          Adaptation vs.
          <br />
          Rigidity
        </>
      ),
      traditional:
        "Traditional revision confines you to a one-size-fits-all approach with static content.",
      qflit:
        "Qflit adapts to your learning styles and needs, offering a dynamic, personalized revision journey.",
      icon: <HiMiniBriefcase />,
    },
    {
      aspect: "Active Engagement vs. Passive Memorization",
      traditional: "Traditional methods rely on passive memorization.",
      qflit:
        "Qflit's revision sessions are interactive and thought-provoking, thus encouraging you to learn actively.",
      icon: <HiChatBubbleOvalLeft />,
    },
    {
      aspect: "Real-Time Feedback vs. Delayed Insights",
      traditional:
        "Traditional methods often provide delayed and unhelpful feedback.",
      qflit:
        "Qflit gives you immediate, detailed feedback, guiding you towards improvement and understanding in real-time.",
      icon: <HiBolt />,
    },
  ];
  const subs = [
    "Science- combined",
    "Mathematics",
    "Biology",
    "Chemistry",
    "Physics",
    "Agriculture",
    "Business",
    "... +8 more",
  ];

  const subsAQA = [
    "Religious Studies A",
    "Mathematics",
    "Biology",
    "Combined Science: Trilogy",
    "Physics",
    "Business",
    "Computer Science",
    "Chemistry",
    "Economics",
  ];
  const [active, setActive] = React.useState("aqa");
  return (
    <>
      <Stack
        sx={{
          position: "relative",
          p: {
            xsm: 3,
            xs: 3,
            sm: 5,
            md: 8,
            lg: 8,
            xl: 8,
          },
          pb: 4,
          maxWidth: "1200px",
          borderColor: "rgb(0, 0, 0, 0.025)",
          borderRadius: {
            xs: 5,
            xsm: 5,
            sm: 4,
            md: 7,
            lg: 7,
            xl: 7,
          },
          mb: 4,
          margin: "auto",
          boxShadow: "2px 2px 8px 12px rgba(0, 0, 0, 0.01)",
          background:
            "linear-gradient(145deg, rgba(13, 13, 13, 1) 0%, rgba(35, 35, 35, 1) 100%)",
          display: {
            xs: "none",
            xsm: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Typography
          sx={{
            mb: {
              xs: 2,
              xsm: 2,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 3,
            },
            color: "white",
            mt: {
              xsm: 3,
              sm: 3,
              xs: 3,
              md: 0,
              lg: 0,
              xl: 0,
            },
            fontSize: {
              xs: 24,
              xsm: 24,
              sm: 32,
              md: 32,
              lg: 32,
              xl: 32,
            },
          }}
          variant="h5"
        >
          <b>Traditional Revision vs. Qflit</b>
        </Typography>

        <Stack
          sx={{ mt: 2 }}
          spacing={2}
          direction={{
            xs: "column",
            xsm: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          }}
        >
          <Stack sx={{ flex: 1 }}>
            {comparisons.map((item, index) => (
              <Stack
                sx={{
                  mb: 1,
                  borderRadius: 4,
                  // background: "white",
                  boxShadow:
                    activeIndex === index
                      ? "2px 2px 8px 12px rgba(0, 0, 0, 0.02)"
                      : "",
                  p: 3,
                  color: "#E4FEFF",
                  background: "#191819",
                  // boxShadow: "2px 2px 8px 12px rgba(0, 0, 0, 0.02)",
                }}
              >
                <Stack sx={{ mb: 1 }} spacing={2} direction="row">
                  <Typography
                    variant="h6"
                    sx={{
                      flex: 1,
                      fontSize: {
                        xs: 16,
                        xsm: 16,
                        sm: 22,
                        md: 22,
                        lg: 22,
                        xl: 22,
                      },
                    }}
                  >
                    <b>{item.aspect}</b>
                  </Typography>
                  <IconButton
                    sx={{ color: "white" }}
                    onClick={() => {
                      setIndex(activeIndex === index ? -1 : index);
                    }}
                  >
                    {activeIndex === index ? (
                      <HiChevronDoubleUp />
                    ) : (
                      <HiChevronDoubleDown />
                    )}
                  </IconButton>
                </Stack>
                <Collapse in={activeIndex === index}>
                  <Stack>
                    <Stack
                      sx={{ alignItems: "center", mb: 1 }}
                      spacing={2}
                      direction="row"
                    >
                      <HiXCircle size={32} color="red" />
                      <Typography sx={{ flex: 1, fontSize: 18 }}>
                        {item.traditional}
                      </Typography>
                    </Stack>

                    <Stack
                      sx={{ alignItems: "center" }}
                      spacing={2}
                      direction="row"
                    >
                      <HiCheckCircle size={32} color="#00AD00" />
                      <Typography
                        sx={{ flex: 1, fontWeight: "500", fontSize: 18 }}
                      >
                        {item.qflit}
                      </Typography>
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
            ))}
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <Stack
              sx={{
                width: "100%",
                p: 2,
                borderRadius: 5,
                background: "white",
                //border: 1,
                borderColor: "blue",
              }}
            >
              <img
                src={feedbackImg}
                alt="screenshot"
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: "relative",

          pb: 4,
          maxWidth: "1200px",
          borderRadius: {
            xs: 5,
            xsm: 5,
            sm: 4,
            md: 7,
            lg: 7,
            xl: 7,
          },
          mb: {
            xsm: 0,
            sm: 0,
            xs: 0,
            md: 4,
            lg: 4,
          },
          display: {
            xs: "flex",
            xsm: "flex",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Stack sx={{ p: 2 }}>
          <img
            src={girlReal}
            alt="girl"
            style={{
              objectFit: "contain",

              width: "100%",
              borderRadius: 16,
            }}
          />
        </Stack>
        <Typography
          sx={{
            m: 2.5,
            maxWidth: 300,
            textAlign: "center",
            alignSelf: "center",
            mb: 0,
          }}
          variant="h6"
        >
          <b>Qflit sets itself apart from traditional revision.</b>
        </Typography>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            width: "100vw",
            overflow: "auto",
            pl: 2.5,
            pr: 3,
            pt: 3,
            pb: 4,
          }}
        >
          {comparisons.map((item, index) => (
            <Stack
              sx={{
                mb: 1,
                borderRadius: 5,

                p: 3,

                boxShadow: "2px 2px 7px 10px rgba(0, 0, 0, 0.015)",

                position: "relative",
                border: 0,
                //borderBottom: 1,
                //  borderLeft: 1,
                //borderTop: 0,
                borderColor: "rgb(0, 223, 37, 0.5)",
                // borderTopRightRadius: 0,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                //  justifyContent="space-between"
                sx={{ mb: 2 }}
                spacing={2}
              >
                <Avatar
                  sx={{
                    color: "rgb(0, 223, 37)",
                    background: "rgb(0, 223, 37, 0.05)",
                  }}
                >
                  {item.icon}
                </Avatar>
                <Typography sx={{ fontSize: 16 }}>
                  <b>{item.aspect}</b>
                </Typography>
              </Stack>
              <Divider sx={{ mt: 1, mb: 2, opacity: 0.25 }} />
              <Stack sx={{ width: "65vw" }}>
                <Stack
                  sx={{ mb: 2, alignItems: "center" }}
                  spacing={1}
                  direction="row"
                >
                  <HiXMark color="red" style={{ opacity: 0.5 }} size={22} />
                  <Typography sx={{ flex: 1 }}>{item.traditional}</Typography>
                </Stack>

                <Stack
                  sx={{ alignItems: "center" }}
                  spacing={1}
                  direction="row"
                >
                  <HiCheck color="#00DF25" style={{}} size={22} />
                  <Typography sx={{ flex: 1 }}>{item.qflit}</Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack id="subjects" />
      <Stack
        sx={{
          p: 3,
          display: {
            xs: "none",
            xsm: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      />
      <Stack
        sx={{
          position: "relative",
          maxWidth: "1200px",
          alignSelf: "center",
          margin: "auto",
          borderRadius: {
            xs: 0,
            sm: 0,
            xsm: 0,
            md: 7,
            lg: 7,
            xl: 7,
          },
          mb: {
            xs: 0,
            xsm: 0,
            sm: 0,
            md: 7,
            lg: 7,
            xl: 7,
          },
          background: "#F3F6FD",
          p: {
            xs: 2,
            xsm: 2,
            sm: 3,
            md: 6,
            lg: 8,
            xl: 8,
          },
          display: {
            xs: "none",
            xsm: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Stack
          spacing={{
            xs: 0,
            sm: 0,
            xsm: 0,
            md: 4,
            lg: 4,
            xl: 4,
          }}
          sx={{ alignItems: "center" }}
          direction="row"
        >
          <Stack
            sx={{
              display: {
                xs: "none",
                sm: "none",
                xsm: "none",
                md: "inherit",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <img
              src={girl}
              alt="logo"
              style={{ objectFit: "contain", width: 300 }}
            />
          </Stack>
          <Stack
            sx={{
              pt: 5,
              pl: {
                sm: 0,
                xsm: 0,
                xs: 0,
                md: 3,
                lg: 3,
                xl: 3,
              },
              pr: {
                sm: 0,
                xsm: 0,
                xs: 0,
                md: 3,
                lg: 3,
                xl: 3,
              },
            }}
          >
            <Stack sx={{ alignItems: "center" }} spacing={2} direction="row">
              <Stack>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 32,
                      xsm: 32,
                      sm: 32,
                      md: 48,
                      xl: 48,
                      lg: 48,
                    },
                    ml: 2,
                  }}
                  variant="h3"
                >
                  <b>Subjects on Qflit</b>
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: {
                    xs: "flex",
                    sm: "flex",
                    xsm: "flex",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <img
                  src={girl}
                  alt="logo"
                  style={{ objectFit: "contain", width: 110 }}
                />
              </Stack>
            </Stack>

            <Stack>
              <Stack
                sx={{
                  borderRadius: 32,
                  p: 1,
                  background: "rgb(0, 0, 0, 0.025)",
                  alignSelf: "flex-start",
                }}
                direction="row"
                alignItems="center"
              >
                <ButtonBase
                  onClick={() => {
                    setActive("aqa");
                  }}
                  sx={{
                    borderRadius: 32,
                    background:
                      active === "aqa" ? "#FDDE55" : "rgb(0, 0, 0, 0)",
                    p: 1.5,
                    pr: 3,
                    pl: 3,
                    mr: 0.5,
                  }}
                >
                  <Typography>
                    <b>AQA</b>
                  </Typography>
                </ButtonBase>
                <ButtonBase
                  onClick={() => {
                    setActive("Cambridge");
                  }}
                  sx={{
                    borderRadius: 32,
                    background:
                      active !== "aqa" ? "#FDDE55" : "rgb(0, 0, 0, 0)",
                    p: 1.5,
                  }}
                >
                  <Typography>
                    <b>Cambridge</b>
                  </Typography>
                </ButtonBase>
              </Stack>
              <Typography
                sx={{
                  fontSize: {
                    xs: 18,
                    sm: 18,
                    xsm: 18,
                    lg: 24,
                    xl: 24,
                    md: 24,
                  },
                  maxWidth: 500,
                  mt: 2,
                  ml: 2,
                }}
              >
                Qflit offers up to {active === "aqa" ? "9" : "15"} subjects
                across the{" "}
                <b>{active === "aqa" ? "AQA GCSE" : "Cambridge IGCSE"}</b>{" "}
                curriculum. All questions are created to the dictates of each
                syllabus.
              </Typography>
              <Stack sx={{}}>
                <Stack
                  sx={{ alignItems: "center", maxWidth: 550, mt: 2 }}
                  direction="row"
                  flexWrap="wrap"
                >
                  {(active === "aqa" ? subsAQA : subs).map((x) => (
                    <Chip
                      sx={{
                        mb: 1,
                        mr: 1,
                        borderRadius: 32,
                        background: "rgb(0, 0, 255, 0.025)",
                      }}
                      label={x}
                    />
                  ))}
                </Stack>
                {false && (
                  <Button
                    color="inherit"
                    endIcon={<HiArrowLongRight />}
                    onClick={() => {}}
                    disableElevation
                    sx={{
                      mt: 2,
                      borderRadius: 32,
                      textTransform: "none",
                      height: 55,

                      pl: 3,
                      pr: 3,
                      alignSelf: "flex-start",
                    }}
                  >
                    View all subjects
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: "relative",
          p: 3,
          display: {
            xs: "flex",
            xsm: "flex",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },

          border: 1,
          borderRadius: 7,
          borderColor: "rgb(0, 0, 0, 0.05)",
          mr: 2,
          ml: 2,
          mb: 3,
        }}
      >
        <Stack sx={{}}>
          <Typography
            sx={{
              ml: 2,
              mt: 1,
            }}
            variant="h6"
          >
            <b>Unbeatable coverage</b>
          </Typography>
        </Stack>
        <Stack>
          <Stack
            sx={{
              borderRadius: 32,
              p: 1,
              background: "rgb(0, 0, 0, 0.025)",
              alignSelf: "flex-start",
              mt: 1,
            }}
            direction="row"
            alignItems="center"
          >
            <ButtonBase
              onClick={() => {
                setActive("aqa");
              }}
              sx={{
                borderRadius: 32,
                background: active === "aqa" ? "#00FF64" : "rgb(0, 0, 0, 0)",
                p: 1.5,
                pr: 2,
                pl: 2,
                mr: 0.5,
              }}
            >
              <Typography>AQA</Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                setActive("Cambridge");
              }}
              sx={{
                borderRadius: 32,
                background: active !== "aqa" ? "#00FF64" : "rgb(0, 0, 0, 0)",
                p: 1.5,
              }}
            >
              <Typography>Cambridge</Typography>
            </ButtonBase>
          </Stack>
          <Typography
            sx={{
              fontSize: {
                xs: 18,
                sm: 18,
                xsm: 18,
                lg: 24,
                xl: 24,
                md: 24,
              },
              maxWidth: 500,
              mt: 2,
              ml: 2,
              //textAlign: "center",
            }}
          >
            Qflit offers up to {active === "aqa" ? "9" : "15"} subjects across
            the <b>{active === "aqa" ? "AQA GCSE" : "Cambridge IGCSE"}</b>{" "}
            curriculum. All questions are created to the dictates of each
            syllabus.
          </Typography>
          <Stack sx={{}}>
            <Stack
              sx={{
                // alignItems: "center",
                maxWidth: 330,
                mt: 2,
                // justifyContent: "center",
                // alignSelf: "center",
                ml: 2,
              }}
              direction="row"
              flexWrap="wrap"
            >
              {(active === "aqa" ? subsAQA : subs).map((x) => (
                <Chip
                  sx={{
                    mb: 1,
                    mr: 1,
                    borderRadius: 32,
                    background: "rgb(0, 0, 255, 0.025)",
                  }}
                  label={x}
                />
              ))}
            </Stack>
            {false && (
              <Button
                color="success"
                endIcon={<HiArrowLongRight />}
                onClick={() => {}}
                disableElevation
                variant="contained"
                sx={{
                  mt: 2,
                  borderRadius: 32,
                  textTransform: "none",
                  height: 55,

                  pl: 3,
                  pr: 3,
                  mr: 2,
                  ml: 2,
                  background: "rgb(0, 202, 35, 0.05)",
                  color: "rgb(0, 202, 35)",
                }}
              >
                View all subjects
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

function AboutStoryboard() {
  const description = `Transform your revision for GCSE/IGCSE exams with Qflit, the
          nexus of AI and personalized learning. Our web-app provides an
          adaptive exam prep experience for a wide range of subjects. It allows
          you to move beyond mere memorization and engage effectively with your
          study material.`;
  return (
    <>
      <Stack
        sx={{
          p: 3,
          display: {
            xsm: "flex",
            xs: "flex",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
          textAlign: "left",

          boxShadow: "2px 2px 8px 12px rgba(0, 109, 255, 0.03)",
          borderRadius: 5,
          ml: 2,
          mr: 2,
          mt: 4,
        }}
      >
        <Stack sx={{ alignItems: "center" }} direction="row" spacing={1}>
          <CiMemoPad size={24} />
          <Typography
            sx={{
              mt: 2,
              //  maxWidth: 180,
            }}
            variant="h6"
          >
            <b>
              A new <span style={{ color: "#0D7D60" }}>frontier</span> in
              learning
            </b>
          </Typography>
        </Stack>

        <Typography
          sx={{
            mt: 1,
            fontSize: 18,
          }}
        >
          {description}
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: 4,
          pt: {
            xs: 4,
            xsm: 4,
            sm: 4,
            md: 12,
            lg: 12,
            xl: 12,
          },
          alignItems: "center",
          pb: 0,
          display: {
            xsm: "none",
            xs: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Chip
          sx={{
            borderRadius: 2,
            p: 2,
            fontWeight: "500",
            color: "#0D7D60",
            borderColor: "#0D7D60",
          }}
          size="large"
          color="primary"
          variant="outlined"
          label="Cambridge IGCSE "
        />
        <Typography
          sx={{
            display: {
              xsm: "none",
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            mt: 2,
          }}
          variant="h4"
        >
          <b>
            A New <span style={{ color: "#0D7D60" }}>Frontier</span> in Learning
          </b>
        </Typography>
        <Typography
          sx={{
            display: {
              xsm: "flex",
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            mt: 2,
          }}
          variant="h6"
        >
          <b>
            A New <span style={{ color: "#0D7D60" }}>Frontier</span> in Learning
          </b>
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            maxWidth: 700,
            mt: 1,
            fontWeight: "500",
            fontSize: {
              xsm: 18,
              xs: 18,
              sm: 18,
              md: 20,
              lg: 20,
              xl: 20,
            },
          }}
        >
          {description}
        </Typography>
      </Stack>
    </>
  );
}

function EnglishAndMath() {
  const labels = {
    mathPractice: `Revise for math effortlessly with our app, featuring over 1,500
              questions across all the topics. Equipped with a user-friendly
              equation input box and advanced OCR technology, it enables quick
              digitalization of handwritten answers, streamlining your IGCSE
              math studies for a more engaging learning experience.`,
    englishPractice: `Boost your English skills with our app, offering rigorous essay and comprehension passage exercises. It accurately evaluates your work, identifying grammatical, vocabulary, and spelling errors, and provides constructive feedback. Designed to mimic real exam scenarios, the app guides you with strategies for improvement, ensuring thorough preparation for IGCSE English exams.`,
  };
  return (
    <>
      <Stack
        spacing={4}
        sx={{
          position: "relative",
          maxWidth: "1200px",
          borderColor: "rgb(0, 0, 0, 0.025)",
          borderRadius: {
            sm: 0,
            xs: 0,
            xsm: 0,
            md: 7,
            xl: 7,
            lg: 7,
          },

          margin: "auto",
          background: `url('${bgArt}') center`,
          p: {
            sm: 3,
            xsm: 3,
            xs: 3,
            md: 8,
            lg: 8,
            xl: 8,
          },

          color: "white",
          display: {
            xsm: "none",
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Chip
          sx={{
            alignSelf: "center",
            color: "white",
            background: "rgb(255, 255, 255, 0.1)",
            p: 2,
            borderRadius: 32,
            pt: 2.5,
            pb: 2.5,
          }}
          label="Become a star over a few nights ..."
        />
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            maxWidth: 500,
            alignSelf: "center",
            fontSize: {
              xs: 24,
              xsm: 24,
              sm: 24,
              md: 32,
              xl: 32,
              lg: 32,
            },
          }}
        >
          <b>English and math practice. Can we count you in?</b>
        </Typography>

        <Stack
          spacing={2}
          direction={{
            xs: "column",
            xsm: "column",
            sm: "column",
            md: "row",
          }}
        >
          <Stack
            sx={{
              flex: 1,
              p: 4,
              borderRadius: 7,
              boxShadow: "2px 2px 8px 12px rgba(0, 0, 0, 0.025)",
              background: "rgb(255, 255, 0, 0.2)",
            }}
          >
            <Typography
              sx={{
                maxWidth: 350,
                fontSize: {
                  xs: 28,
                  xsm: 28,
                  sm: 28,
                  md: 36,
                  xl: 36,
                  lg: 36,
                },
              }}
              variant="h5"
            >
              <b>
                <span style={{ color: "#31E866" }}>Full-fledged </span>Math
                Practice
              </b>
            </Typography>

            <Typography sx={{ maxWidth: 400, mt: 2, fontSize: 20 }}>
              {labels.mathPractice}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flex: 1,
              p: 4,
              borderRadius: 7,
              pt: 0,
            }}
          >
            <Typography
              sx={{
                maxWidth: 350,
                fontSize: {
                  xs: 28,
                  xsm: 28,
                  sm: 28,
                  md: 36,
                  xl: 36,
                  lg: 36,
                },
              }}
              variant="h4"
            >
              <b>
                <span style={{ color: "#31E866" }}>Nuanced </span>English
                Revision
              </b>
            </Typography>
            <Chip
              color="warning"
              icon={<HiClock />}
              sx={{ alignSelf: "flex-start", mt: 2 }}
              label="Coming soon"
            />
            <Typography sx={{ maxWidth: 420, mt: 2, fontSize: 20 }}>
              {labels.englishPractice}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          position: "relative",
          maxWidth: "1200px",
          borderColor: "rgb(0, 0, 0, 0.025)",
          borderRadius: 7,

          m: {
            xsm: 2,
            sm: 2,
            xs: 2,
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
          background: `url('${bgArt}') center`,
          color: "white",
          display: {
            xsm: "flex",
            xs: "flex",
            sm: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Stack
          spacing={2}
          direction={{
            xs: "column",
            xsm: "column",
            sm: "column",
            md: "row",
          }}
        >
          <Stack
            sx={{
              flex: 1,
              p: 4,
              borderRadius: 7,
              boxShadow: "2px 2px 8px 12px rgba(0, 0, 0, 0.025)",
            }}
          >
            <Typography
              sx={{
                maxWidth: 350,
                fontSize: {
                  xs: 28,
                  xsm: 28,
                  sm: 28,
                  md: 36,
                  xl: 36,
                  lg: 36,
                },
              }}
              variant="h4"
            >
              <b>
                <span style={{ color: "#31E866" }}>Full-fledged </span>Math
                Practice
              </b>
            </Typography>

            <Typography sx={{ maxWidth: 400, mt: 2, fontSize: 18 }}>
              {labels.mathPractice}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          p: {
            xsm: 0,
            xs: 0,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
        }}
      />
    </>
  );
}

function FeaturesInfographic() {
  return (
    <>
      <Stack
        sx={{
          mt: 7,
          position: "relative",
          pt: 8,
          pb: 4,
          maxWidth: "1200px",
          alignSelf: "center",
          margin: "auto",
          display: {
            xs: "none",
            sm: "none",
            xsm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <img
          src={main_screen}
          alt="screenshot"
          style={{
            width: "100%",
            objectFit: "contain",
          }}
        />
      </Stack>{" "}
      <Stack
        sx={{
          position: "relative",
          pt: 5,
          pb: 4,
          maxWidth: "1200px",
          alignSelf: "center",
          alignItems: "center",
          margin: "auto",
          display: {
            xs: "flex",
            sm: "flex",
            xsm: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <img
          src={mobile_screen}
          alt="screenshot"
          style={{
            width: "98%",
            objectFit: "contain",
            marginRight: -5,
          }}
        />
      </Stack>
    </>
  );
}

function Pricing() {
  const [plans, setPlans] = React.useState([]);

  React.useEffect(() => {
    const updatedPlans = packages.map((plan, index) => {
      let icon;
      let bg;
      switch (index) {
        case 0:
          icon = basicPlanSvg;
          bg = "white";
          break;
        case 1:
          icon = standardPlanSvg;
          bg = "rgb(0, 64, 233, 0.1)";
          break;
        case 2:
          icon = premiumPlanSvg;
          bg = "#00A24C";
          break;
        default:
          icon = null; // or some default icon
      }
      return {
        ...plan,
        bg,
        icon,
      };
    });

    setPlans(updatedPlans);
  }, []);

  return (
    <Stack
      id="pricing"
      sx={{
        width: "100vw",
        maxWidth: "1200px",
        margin: "auto",

        alignItems: "center",
        borderRadius: 7,
        mb: 4,
      }}
    >
      <Stack sx={{ textAlign: "center", alignItems: "center", p: 4, pb: 0 }}>
        <Typography sx={{ maxWidth: 350 }}>
          <b>Affordable charges, infinite value.</b>
        </Typography>
        <Typography
          variant="h2"
          sx={{
            maxWidth: {
              xs: 300,
              xsm: 300,
              sm: 550,
              lg: 550,
              xl: 550,
            },
            mt: 1,
            fontSize: {
              xs: 24,
              xsm: 24,
              sm: 32,
              lg: 56,
              xl: 56,
            },
            mb: 1.5,
          }}
        >
          <b>
            Flexible <span style={{}}>pricing</span> to suit every need
          </b>
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: {
            xsm: 2,
            xs: 2,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },
          overflow: "auto",
          width: {
            xsm: "100vw",
            xs: "100vw",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
        }}
        direction={{
          xs: "row",
          xsm: "row",
          sm: "row",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        spacing={2}
      >
        {plans.map((plan, index) => (
          <Stack
            key={plan.title}
            sx={{
              p: 4,
              borderRadius: 7,
              maxWidth: {
                xs: "inherit",
                xsm: "inherit",
                sm: 420,
                md: 320,
                lg: 400,
                xl: 400,
              },
              boxShadow: "2px 2px 8px 12px rgba(0, 0, 0, 0.01)",
            }}
          >
            <Stack spacing={2} sx={{}} direction="row">
              <img
                src={plan.icon}
                alt=""
                style={{ height: 30, width: 30, objectFit: "contain" }}
              />
              <Typography sx={{}} variant="body2">
                <b>{plan.title.toUpperCase()}</b>
              </Typography>
            </Stack>
            <Typography
              sx={{
                mt: 1,
                minHeight: 130,
                width: {
                  xs: "60vw",
                  xsm: "60vw",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
              }}
            >
              {plan.description}
            </Typography>
            <Typography sx={{ mt: 1 }} variant="h6">
              <b>{plan.price}</b>
              <span style={{ fontSize: 12 }}>/per month</span>
            </Typography>
            <Divider sx={{ mt: 1, mb: 1, opacity: 0.3 }} />
            <Stack sx={{ mt: 2 }} spacing={0.5}>
              {plan.benefits.map((benefit) => (
                <Stack
                  spacing={2}
                  sx={{ alignItems: "center" }}
                  direction="row"
                  key={benefit}
                >
                  <HiCheckCircle color="#004BF0" style={{}} />
                  <Typography>{benefit}</Typography>
                </Stack>
              ))}
            </Stack>
            <Button
              color="primary"
              onClick={() => {
                window.open("https://app.qflit.com/signup", "_blank");
              }}
              sx={{
                borderRadius: 32,
                mt: 3,
                height: 55,
                textTransform: "none",
              }}
              variant="contained"
              disableElevation
            >
              Sign up for free
            </Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

function Explainer() {
  const labels = {
    description: `Enter the realm of Qflit, where every practice session is an opportunity
        for growth and discovery. Experience a new dimension of learning that's
        engaging, effective, and truly tailored to you.`,
    features: [
      {
        title: "Craft a test",
        description: `Challenge yourself with Qflit's structured, exam-style questions,
              all tailored to your syllabus. Our sessions foster deep understanding and critical thinking.`,
        step: "Step 1",
        icon: <CiPen />,
      },
      {},
      {
        title: "Get feedback",
        description: `Our app analyzes your answers, suggesting improvements and reinforcing strengths. Enhance your understanding with feedback that goes beyond right or wrong. `,
        step: "Step 2",
        icon: <CiMicrophoneOn />,
      },
      {},
      {
        title: "Adaptive lessons",
        description: `When you struggle with a question, our app automatically generates 
        a focused lesson to address the problem area. This approach ensures
        continuous improvement.`,
        step: "Step 3",
        icon: <CiRoute />,
      },
    ],
  };
  return (
    <>
      <Stack id="features" />
      <Stack
        sx={{
          width: "100vw",
          maxWidth: "1200px",
          margin: "auto",
          background: "rgb(55, 237, 80, 0.055)",
          p: 6,
          alignItems: "center",
          borderRadius: 7,
          //  color: "white",
          mb: {
            xs: 0,
            xsm: 0,
            sm: 0,
            md: 4,
            lg: 7,
            xl: 7,
          },
          display: {
            xsm: "none",
            xs: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Chip
          sx={{
            borderRadius: 2,
            p: 2,
            fontWeight: "500",

            border: 0,
            color: "#289F38",
            background: "rgb(52, 218, 74, 0.1)",
          }}
          size="large"
          variant="outlined"
          label="How it works"
        />
        <Typography
          variant="h6"
          sx={{
            mt: 1,
            textAlign: "center",
            display: {
              xsm: "flex",
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <b>
            Our <span style={{ color: "#00CF10" }}>step-by-step</span> approach
          </b>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            mt: 1,
            textAlign: "center",
            display: {
              xsm: "none",
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <b>
            Our <span style={{ color: "#00CF10" }}>step-by-step</span> approach
          </b>
        </Typography>
        <Typography
          sx={{
            mt: 1,
            textAlign: "center",
            maxWidth: 600,
            fontSize: {
              xs: 17,
              xsm: 17,
              sm: 19,
              lg: 19,
              xl: 19,
            },
            mb: 8,
          }}
        >
          {labels.description}
        </Typography>

        <Grid spacing={1} container>
          {labels.features.map((item, index) => {
            if (!item.title) {
              return (
                <Grid item xs={1} xsm={1} sm={1} lg={0.75} xl={0.75} md={0.75}>
                  <Stack
                    sx={{ alignSelf: "center", margin: "auto" }}
                    alignItems="center"
                  >
                    <Avatar
                      sx={{
                        color: "#289F38",
                        background: "rgb(52, 218, 74, 0.1)",
                        mt: 8,
                      }}
                    >
                      <HiArrowRight />
                    </Avatar>
                  </Stack>
                </Grid>
              );
            }
            return (
              <Grid item xsm={12} xs={12} sm={5} md={3.5} lg={3.5} xl={3.5}>
                <Stack sx={{ p: 1 }}>
                  <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                    sx={{ flex: 1 }}
                  >
                    <Typography variant="h6">
                      <b>{item.title}</b>
                    </Typography>
                    <Chip
                      sx={{
                        borderRadius: 2,
                        p: 2,
                        fontWeight: "500",
                        //  color: "#289F38",
                        background: "rgb(52, 218, 74, 0.1)",

                        border: 0,
                      }}
                      size="large"
                      variant="outlined"
                      label={item.step}
                    />
                  </Stack>

                  <Typography sx={{ mt: 2, fontSize: 18 }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
      <Stack
        sx={{
          mb: {
            xs: 0,
            xsm: 0,
            sm: 0,
            md: 4,
            lg: 7,
            xl: 7,
          },
          display: {
            xsm: "flex",
            xs: "flex",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Stack
          sx={{
            p: 3,
            alignItems: "center",
            pt: 0,
          }}
        >
          <Chip
            sx={{
              p: 2,
              fontWeight: "500",

              border: 0,
              color: "#289F38",
              background: "rgb(52, 218, 74, 0.1)",
            }}
            size="large"
            variant="outlined"
            label="How it works"
          />
          <Typography
            variant="h6"
            sx={{
              mt: 1,
              textAlign: "center",
              display: {
                xsm: "flex",
                xs: "flex",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <b>
              Our <span style={{ color: "#00CF10" }}>step-by-step</span>{" "}
              approach
            </b>
          </Typography>

          <Typography
            sx={{
              mt: 1,
              textAlign: "center",

              fontSize: {
                xs: 17,
                xsm: 17,
                sm: 19,
                lg: 19,
                xl: 19,
              },
            }}
          >
            {labels.description}
          </Typography>
        </Stack>

        <Stack
          spacing={2}
          sx={{
            width: "100vw",
            overflow: "auto",
            mb: 3,
            pt: 3,
            pb: 3,
            pl: 3,
            pr: 3,
          }}
          direction="row"
        >
          {labels.features.map((item, index) => {
            if (!item.title) {
              return null;
            }
            return (
              <Stack
                sx={{
                  p: 2.5,
                  borderRadius: 5,
                  boxShadow: "2px 2px 8px 12px rgba(0, 0, 0, 0.015)",
                  //  background: "linear-gradient(to right, #f0f4f8, #e8effa)", // Subtle gradient from light gray-blue to lighter blue
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography>
                    <b>{item.title}</b>
                  </Typography>
                  <Chip
                    icon={item.icon}
                    color="warning"
                    sx={{}}
                    size="large"
                    variant="contained"
                    label={item.step}
                  />
                </Stack>

                <Typography sx={{ mt: 2, width: "65vw" }}>
                  {item.description}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
}

function TestimonialsCarousel() {
  return <div>{/* Testimonials content */}</div>;
}

function Footer() {
  return (
    <Stack
      sx={{
        borderTop: 1,
        borderColor: "rgb(199, 199, 199, 0.1)",
        pr: {
          sm: 3.5,
          xs: 3.5,
          xsm: 3.5,
          md: 8,
          lg: 8,
          xl: 8,
        },
        pl: {
          sm: 3.5,
          xs: 3.5,
          xsm: 3.5,
          md: 8,
          lg: 8,
          xl: 8,
        },
        pt: {
          sm: 5,
          xs: 4,
          xsm: 4,
          md: 8,
          lg: 10,
          xl: 10,
        },
        pb: {
          sm: 5,
          xs: 4,
          xsm: 4,
          md: 8,
          lg: 10,
          xl: 10,
        },
        alignItems: "center",
      }}
    >
      <Stack
        spacing={3}
        sx={{ mb: 5 }}
        direction={{
          xsm: "column",
          xs: "column",
          sm: "row",
          lg: "row",
          md: "row",
          xl: "row",
        }}
      >
        <Stack spacing={2} direction="row">
          <Avatar sx={{ background: "white", color: "black" }}>
            <CiLocationArrow1 />
          </Avatar>
          <Stack>
            <Typography>
              <b>Our address</b>
            </Typography>
            <Typography sx={{ maxWidth: 300 }}>
              HA4 7AE, 2nd Floor College House, 17 King Edwards Road, Ruislip,
              London
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={2} direction="row">
          <Avatar sx={{ background: "white", color: "black" }}>
            <CiPhone />
          </Avatar>
          <Stack>
            <Typography>
              <b>Contact details</b>
            </Typography>
            <Typography sx={{ maxWidth: 300 }}>
              Email: hello@qflit.com
            </Typography>
            <Typography sx={{ maxWidth: 300 }}>
              Phone: +44 7774 823767
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Typography sx={{ opacity: 0.75 }}>
        © 2024 Qflit AI Ltd. All rights reserved.
      </Typography>
    </Stack>
  );
}

function Homepage() {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <Stack
      sx={{ flex: 1, position: "relative", width: "100vw", overflowX: "none" }}
    >
      <Stack
        sx={{
          top: 0,
          left: 0,
          right: 0,
          height: {
            xs: "600px",
            xsm: "600px",
            sm: "600px",
            md: "740px",
            lg: "740px",
            xl: "740px",
          },
          position: "absolute",
        }}
      >
        <Stack
          sx={{
            backgroundImage: `url(${blurOverlay})`, // Replace with your image path
            backgroundSize: "cover", // Cover the entire area
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat",
            opacity: 0.1,
            zIndex: -1,
            width: "100%",
            height: "100%",
            display: {
              xs: "none",
              xsm: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        ></Stack>
        <Stack
          sx={{
            content: '""',
            position: "absolute",
            zIndex: 4,
            bottom: 0, // Positioned at the bottom
            left: 0,
            right: 0,
            opacity: 1,
            display: {
              xs: "none",
              xsm: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            height: "15vh", // Height of the shape
            backgroundColor: "white", // Color of the shape
            clipPath: "polygon(0 100%, 50% 80%, 100% 100%, 100% 100%, 0 100%)", // Custom skewed shape
          }}
        ></Stack>
      </Stack>
      <Stack
        sx={{
          width: "100vw",
          maxWidth: "1200px",
          zIndex: 2,
          margin: "auto",
          alignSelf: "center",
        }}
      >
        <Stack
          sx={{
            flex: 1,
          }}
        >
          <AnimatedComponentWrapper alwaysVisible={true} name="NavBar">
            <NavBar setMenuOpen={setMenuOpen} />
          </AnimatedComponentWrapper>
          <AnimatedComponentWrapper name="HeroBanner" alwaysVisible={true}>
            <HeroBanner />
          </AnimatedComponentWrapper>
          <AnimatedComponentWrapper name="img-hor">
            <Stack
              sx={{
                display: {
                  xs: "flex",
                  xsm: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <AnimatedImageHor />
            </Stack>
          </AnimatedComponentWrapper>
          <AnimatedComponentWrapper name="About" alwaysVisible={true}>
            <AboutStoryboard />
          </AnimatedComponentWrapper>
        </Stack>
      </Stack>
      <AnimatedComponentWrapper name="Features" alwaysVisible={true}>
        <FeaturesInfographic />
      </AnimatedComponentWrapper>
      <AnimatedComponentWrapper name="Explainer" r>
        <Explainer />
      </AnimatedComponentWrapper>
      <AnimatedComponentWrapper name="SubjectsShowcase">
        <SubjectsShowcase />
      </AnimatedComponentWrapper>{" "}
      <AnimatedComponentWrapper name="Pricing">
        <Pricing />
      </AnimatedComponentWrapper>
      <AnimatedComponentWrapper>
        <EnglishAndMath name="EnglishMath" />
      </AnimatedComponentWrapper>
      <AnimatedComponentWrapper>
        <TestimonialsCarousel name="Testimonials" />
      </AnimatedComponentWrapper>
      <AnimatedComponentWrapper>
        <Footer name="Footer" />
      </AnimatedComponentWrapper>
      <Drawer
        anchor="right"
        PaperProps={{
          sx: {
            width: "100vw",
            maxWidth: "300px",
            p: 3,
          },
        }}
        onClose={() => {
          setMenuOpen(false);
        }}
        open={menuOpen}
      >
        <Stack
          justifyContent="space-between"
          sx={{ alignItems: "center" }}
          direction="row"
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "30px",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: "100%", objectFit: "contain" }}
            />

            <Typography>
              <b>Qflit AI</b>
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <HiXMark />
          </IconButton>
        </Stack>
        <Divider sx={{ mt: 2, mb: 2, opacity: 0.25 }} />

        <Stack sx={{}} spacing={3}>
          {menuOptions.map((item) => {
            return (
              <Stack spacing={2} direction="row">
                {item.icon}
                <Typography>{item.title}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Drawer>
    </Stack>
  );
}

export { Homepage };
