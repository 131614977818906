import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";

const TermsOfService = () => {
  const today = new Date().toLocaleDateString();

  return (
    <Container
      sx={{
        p: 4,
        "& .MuiTypography-root": {
          maxWidth: 600,
        },
      }}
    >
      <Typography variant="h4" gutterBottom>
        Qflit - Terms of Service
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last Updated: {today}
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to Qflit, an AI-powered revision application designed to assist
        students preparing for Cambridge IGCSE examinations. Please read these
        Terms of Service ("Terms") carefully before using the Qflit application
        ("Service").
      </Typography>

      <Typography variant="body1" paragraph>
        By accessing or using the Service, you agree to be bound by these Terms.
        If you disagree with any part of the Terms, you may not access the
        Service.
      </Typography>

      <Box mt={3} mb={3}>
        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using Qflit, you agree to be bound by these Terms, all
          applicable laws and regulations, and any other applicable policies,
          terms, and guidelines established by Qflit.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Service Description
        </Typography>
        <Typography variant="body1" paragraph>
          Qflit provides an artificial intelligence-backed platform to mark
          answers to structured questions, offering detailed feedback and
          examiner-like marking for Cambridge IGCSE revision.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Registration
        </Typography>
        <Typography variant="body1" paragraph>
          Users can register for Qflit using their Google or Facebook accounts.
          By registering with these platforms, users agree to share certain
          personal information with Qflit for the purpose of creating an account
          and enhancing the user experience.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content, features, and functionality on Qflit are the exclusive
          property of Qflit and are protected by international copyright,
          trademark, and other intellectual property rights laws. Misuse of any
          trademarks, logos, or any other proprietary information is strictly
          prohibited.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by applicable law, Qflit shall not be
          liable for any indirect, incidental, special, consequential or
          punitive damages, including without limitation, loss of profits, data,
          or use, incurred by you or any third party, whether in action for
          contract or tort.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          Users are expected to use the Qflit platform in a responsible and
          lawful manner. Any misuse, including spreading misinformation,
          harassment, or engaging in fraudulent activities, may lead to
          termination of user access.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          Qflit reserves the right to terminate or suspend access to the Service
          immediately, without prior notice, for conduct that Qflit believes
          violates these Terms or is harmful to other users, Qflit, or third
          parties, or for any other reason.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Data Protection
        </Typography>
        <Typography variant="body1" paragraph>
          Qflit is committed to ensuring that your privacy is protected. The
          personal information collected during registration is used to provide
          you with services and is stored in compliance with data protection
          laws. It is not shared with third parties unless necessary for service
          provision or required by law.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Changes to the Terms
        </Typography>
        <Typography variant="body1" paragraph>
          Qflit reserves the right to modify or replace these Terms at any time.
          It is your responsibility to review these Terms periodically for
          changes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms are governed by and construed in accordance with the laws
          of England.
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Contact
        </Typography>
        <Typography variant="body1" paragraph>
          For any questions about these Terms, please contact us at{" "}
          <Link href="mailto:support@qflit.com">Qflit Support Email</Link>.
        </Typography>
      </Box>

      <Typography variant="body2" paragraph>
        By using the Qflit Service, you acknowledge that you have read,
        understood, and agree to be bound by these Terms of Service.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
