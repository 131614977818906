import React from "react";
import { Container, Typography, Link } from "@mui/material";

function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy for Qflit
      </Typography>
      <Typography variant="body1" paragraph>
        <i>Last updated: {new Date().toLocaleDateString()}</i>
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for choosing to be part of our community at Qflit ("we", "us",
        or "our"). We value your privacy and are committed to protecting your
        personal information. This Privacy Policy outlines our policies
        regarding the collection, use, and sharing of your information through
        the Qflit platform.
      </Typography>
      <Typography variant="h5" gutterBottom>
        1. What Information Do We Collect?
      </Typography>
      <Typography variant="body1" paragraph>
        When you use Qflit, we collect the following types of information:
        <ul>
          <li>
            Profile Information: We may collect details about your sex, country,
            curriculum, and the subjects you are taking. This information helps
            us tailor our services to better suit your educational needs.
          </li>
          <li>
            Account Information: This includes your email address and password,
            which you provide when creating an account.
          </li>
          <li>
            Communication: If you contact us directly, we may receive additional
            information about you.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" gutterBottom>
        2. How Do We Use Your Information?
      </Typography>
      <Typography variant="body1" paragraph>
        We use the information we collect or receive:
        <ul>
          <li>To provide, maintain, and improve our services.</li>
          <li>
            To understand and analyze how you use our platform, helping us to
            develop new products, services, features, and functionality.
          </li>
          <li>
            To communicate with you, provide you with updates and other
            information, and for marketing and promotional purposes.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" gutterBottom>
        3. How Do We Store and Protect Your Information?
      </Typography>
      <Typography variant="body1" paragraph>
        We take data protection seriously and use various measures to protect
        your information. While no system is infallible, we strive to keep our
        systems secure and constantly update our practices to tackle new
        security threats.
      </Typography>
      <Typography variant="body1" paragraph>
        Note: This privacy policy is a general template and may not cover all
        legal requirements. It's essential to consult with legal counsel to
        ensure compliance with all applicable laws.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to <Link href="mailto:support@qflit.com">contact us</Link>.
      </Typography>
    </Container>
  );
}

export default PrivacyPolicy;
